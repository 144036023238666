<template>
    <div>
        <div class="d-flex justify-content-center w-100">
            <vs-tooltip bottom border>
                <vs-button icon circle @click="openNewFilter">
                    <img
                        src="@/assets/images/filter.svg"
                        height="15"
                        width="15"
                        alt="reload img"
                    />
                </vs-button>

                <template #tooltip> Modificar filtros de búsqueda </template>
            </vs-tooltip>
        </div>
        <div class="d-flex flex-column mt-1">
            <dates-range-filter
                v-if="activeFilters.includes('dateRange')"
                v-model="dateRange"
                @change="applyFilter"
            />
            <time-ago-filter
                v-if="activeFilters.includes('timeAgo')"
                v-model="timeAgo"
                @change="applyFilter"
            />
        </div>

        <vs-dialog
            v-model="isOpenModal"
            scroll
            overflow-hidden
            auto-width
            @close="pushNewFilter"
        >
            <template #header>
                <div class="d-flex flex-column">
                    <div
                        class="mx-4 mt-2 d-flex flex-column flex-lg-row align-items-center"
                    >
                        <h3 class="text-center">Seleccione los filtros</h3>

                        <div>
                            <vs-button
                                :disabled="!filters.length"
                                icon
                                circle
                                @click="pushNewFilter"
                            >
                                ✔️
                            </vs-button>
                        </div>
                    </div>
                </div>
            </template>
            <div class="con-content">
                <cards-selector v-model="filters" :base="$data.$baseFilters" />
            </div>
        </vs-dialog>
    </div>
</template>

<script>
import { timeFilters } from "@/common/fields/filters-query";

import CardsSelector from "@/components/utils/CardsSelector.vue";

export default {
    name: "OrdersFilter",
    components: {
        CardsSelector,
        TimeAgoFilter: () => import("@/components/utils/TimeAgoFilter.vue"),
        DatesRangeFilter: () =>
            import("@/components/utils/DatesRangeFilter.vue")
    },
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        itemsCurrent: {
            default: () => ({}),
            type: Object,
            require: true
        }
    },
    data: () => ({
        activeFilters: [],
        isOpenModal: false,
        dateRange: {},
        timeAgo: { timeScale: "agoDays", timeValue: 7 },
        filters: [],
        $baseFilters: timeFilters
    }),
    watch: {
        filters() {
            this.applyFilter();
        }
    },
    mounted() {
        this.activeFilters = Object.keys(this.itemsCurrent);
    },
    methods: {
        openNewFilter() {
            this.isOpenModal = true;
        },
        pushNewFilter() {
            this.activeFilters = [...this.filters];
            this.isOpenModal = false;
            this.applyFilter();
        },
        applyFilter() {
            this.$emit("change", {
                ...{
                    ...(this.activeFilters.includes("dateRange")
                        ? this.dateRange
                        : null)
                },
                ...{
                    ...(this.activeFilters.includes("timeAgo")
                        ? {
                              [this.timeAgo.timeScale]: this.timeAgo.timeValue
                          }
                        : null)
                }
            });
        }
    }
};
</script>
